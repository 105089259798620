import React from "react";
import Lottie from "lottie-react";
import loading from "./loading.json";

function LoadingComponent() {
  return (
    <div className="flex justify-center items-center text-center h-screen">
      <Lottie animationData={loading} loop={true} />
    </div>
  );
}

export default LoadingComponent;
