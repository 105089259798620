import React, { useCallback, useEffect } from "react";
import { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { appRoutesObj } from "./app.paths";
import LoadingComponent from "./components/common/loding-component";

const FooterContactUsPage = React.lazy(
  () => import("./pages/user/footer-contact-us-page")
);
const LandingPage = React.lazy(() => import("./pages/user/landing-page"));
const OurTechnologyPage = React.lazy(
  () => import("./pages/user/our-technology-page")
);
const HowItWorksPage = React.lazy(
  () => import("./pages/user/how-it-works-page")
);

const BlogPage = React.lazy(() => import("./pages/user/blog-page"));
const CommonQuestionsPage = React.lazy(
  () => import("./pages/user/common-questions-page")
);
const HomeAdmainPage = React.lazy(
  () => import("./pages/admin/home-admain-page")
);
const FqaAdmainPage = React.lazy(() => import("./pages/admin/faq-admain-page"));
const LinkSocialMediaAdmainPage = React.lazy(
  () => import("./pages/admin/link-social-media-admain-page")
);
const ArticlesAdmainPage = React.lazy(
  () => import("./pages/admin/articles-admain-page")
);
const NotFoundPage = React.lazy(() => import("./pages/404"));
const LogInPage = React.lazy(() => import("./pages/admin/log-in-page"));
const SendEmailPage = React.lazy(() => import("./pages/admin/send-email-page"));
const NewPasswordPage = React.lazy(
  () => import("./pages/admin/new-password-page")
);
const EmptyHome = React.lazy(() => import("./pages/admin/empty-home-page"));
const EditArticlesAdmainPage = React.lazy(
  () => import("./pages/admin/edit-articales-admin-page")
);
const EditFaQAdmainPage = React.lazy(
  () => import("./pages/admin/edit-faq-admin-page")
);
const ViweArticlPage = React.lazy(
  () => import("./pages/user/viwe-artical-page")
);

const language = localStorage.getItem("language");
const withSuspense = (WrappedComponent: JSX.Element) => {
  return (
    <Suspense
      fallback={
        <div className="flex justify-center items-center mx-auto my-auto">
          <LoadingComponent />
        </div>
      }
    >
      {WrappedComponent}
    </Suspense>
  );
};

export function AppRouting() {
  const setLanguage = useCallback(async () => {
    if (!language) {
      localStorage.setItem("language", "en");
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    setLanguage();
  }, []);

  return (
    <Suspense
      fallback={
        <div className="flex justify-center items-center mx-auto my-auto">
          <LoadingComponent />
        </div>
      }
    >
      <BrowserRouter>
        <div
          style={{
            direction: `${language === "en" ? "ltr" : "rtl"}`,
          }}
        >
          <Routes>
            <Route
              key="notDefined"
              path="*"
              element={withSuspense(<NotFoundPage />)}
            />
            <Route
              key="landingPage"
              path={appRoutesObj.getLandingPagePath()}
              element={withSuspense(<LandingPage />)}
            />
            <Route
              key="ourTechnologyPage"
              path={appRoutesObj.getOurTechnologyPagePath()}
              element={withSuspense(<OurTechnologyPage />)}
            />
            <Route
              key="howItWorksPage"
              path={appRoutesObj.getHowItWorksPagePath()}
              element={withSuspense(<HowItWorksPage />)}
            />

            <Route
              key="footerHowItWorksPage"
              path={appRoutesObj.getContactUsFooterPagePath()}
              element={withSuspense(<FooterContactUsPage />)}
            />
            <Route
              key="blogPage"
              path={appRoutesObj.getBlogPagePath()}
              element={withSuspense(<BlogPage />)}
            />
            <Route
              key="commonQuestionsPage"
              path={appRoutesObj.getFAQPagePath()}
              element={withSuspense(<CommonQuestionsPage />)}
            />
            <Route
              key="admin-faq"
              path={appRoutesObj.getAdminFAQPagePath()}
              element={withSuspense(<FqaAdmainPage />)}
            />
            <Route
              key="admin-home"
              path={appRoutesObj.getAdminHomePagePath()}
              element={withSuspense(<HomeAdmainPage />)}
            />
            <Route
              key="admin-articles"
              path={appRoutesObj.getAdminArticlesPagePath()}
              element={withSuspense(<ArticlesAdmainPage />)}
            />
            <Route
              key="admin-social-media"
              path={appRoutesObj.getAdminSocialMediaPagePath()}
              element={withSuspense(<LinkSocialMediaAdmainPage />)}
            />
            <Route
              key="logInPage"
              path={appRoutesObj.getLogInPagePagePath()}
              element={withSuspense(<LogInPage />)}
            />
            <Route
              key="sendEmail"
              path={appRoutesObj.getSendEmailPagePath()}
              element={withSuspense(<SendEmailPage />)}
            />
            <Route
              key="newPassword"
              path={appRoutesObj.getNewPasswordPagePath()}
              element={withSuspense(<NewPasswordPage />)}
            />
            <Route
              key="emptyHome"
              path={appRoutesObj.getEmptyHomePagePath()}
              element={withSuspense(<EmptyHome />)}
            />
            <Route
              key="editArticals"
              path={appRoutesObj.getEditArticlesPagePath()}
              element={withSuspense(<EditArticlesAdmainPage />)}
            />
            <Route
              key="editFaQ"
              path={appRoutesObj.getEditFaQPagePath()}
              element={withSuspense(<EditFaQAdmainPage />)}
            />
            
            
          </Routes>
        </div>
      </BrowserRouter>
    </Suspense>
  );
}
