class AppRoutes {
  private static instance: AppRoutes;
  private constructor() {}
  public static getInstance(): AppRoutes {
    if (!this.instance) {
      return (AppRoutes.instance = new AppRoutes());
    }
    return this.instance;
  }
  // landing page home
  getLandingPagePath(): string {
    return "/";
  }
  getLandingPageUrl(): string {
    return this.getLandingPagePath();
  }
  // our technology page
  getOurTechnologyPagePath(): string {
    return "/our-technology";
  }
  getOurTechnologyPageUrl(): string {
    return this.getOurTechnologyPagePath();
  }
  //how it works page nav
  getHowItWorksPagePath(): string {
    return "/how-it-works";
  }
  getHowItWorksPageUrl(): string {
    return this.getHowItWorksPagePath();
  }
  //how it works page footer
  getHowItWorksFooterPagePath(): string {
    return "/HowItWorks";
  }
  getHowItWorksFooterPageUrl(): string {
    return this.getHowItWorksFooterPagePath();
  }
  //contact us page footer
  getContactUsFooterPagePath(): string {
    return "/contact-us";
  }
  getContactUsFooterPageUrl(): string {
    return this.getContactUsFooterPagePath();
  }
  //Articles page in usre side
  getBlogPagePath(): string {
    return "/blog";
  }
  getBlogPageUrl(): string {
    return this.getBlogPagePath();
  }
  // Common Questions page in usre side
  getFAQPagePath(): string {
    return "/faq";
  }
  getFAQPageUrl(): string {
    return this.getFAQPagePath();
  }
  // Common Questions page in admin side
  getAdminFAQPagePath(): string {
    return "/admin-faq";
  }
  getAdminFAQPageUrl(): string {
    return this.getAdminFAQPagePath();
  }
  // home page in admin side
  getAdminHomePagePath(): string {
    return "/admin-home";
  }
  getAdminHomePageUrl(): string {
    return this.getAdminHomePagePath();
  }
  // articles page in admin side
  getAdminArticlesPagePath(): string {
    return "/admin-articles";
  }
  getAdminArticlesPageUrl(): string {
    return this.getAdminArticlesPagePath();
  }
  // social media page in admin side
  getAdminSocialMediaPagePath(): string {
    return "/admin-social-media";
  }
  getAdminSocialMediaPageUrl(): string {
    return this.getAdminSocialMediaPagePath();
  }
  // login page in admin side
  getLogInPagePagePath(): string {
    return "/admin-login-page";
  }
  getLogInPagePageUrl(): string {
    return this.getLogInPagePagePath();
  }
  // forgot password page in admin side
  getSendEmailPagePath(): string {
    return "/admin-forgot-password-page";
  }
  getSendEmailPageUrl(): string {
    return this.getSendEmailPagePath();
  }
  // new password page in admin side
  getNewPasswordPagePath(): string {
    return "/admin-new-password-page/:id/:otp";
  }
  getNewPasswordPageUrl(): string {
    return this.getNewPasswordPagePath();
  }
  // empty home page in admin side
  getEmptyHomePagePath(): string {
    return "/admin-empty-home";
  }
 
  getEmptyHomePageUrl(): string {
    return this.getEmptyHomePagePath();
  }
  // edit articals page in admin side
  getEditArticlesPagePath(): string {
    return `/admin-edit-articals/:id`;
  }
  getEditArticlesPageUrl(id: string): string {
    return this.getEditArticlesPagePath().replace(":id", id);
  }
  // edit faq page in admin side
  getEditFaQPagePath(): string {
    return `/admin-edit-faq/:id`;
  }
  getEditFaQPageUrl(id: string): string {
    return this.getEditFaQPagePath().replace(":id", id);
  }
  // viwe one artical page in user side
  viweArticlesPagePath(): string {
    return `/viwe-articals/:id`;
  }
  viweArticlesPageUrl(id: string): string {
    return this.viweArticlesPagePath().replace(":id", id);
  }
}

export const appRoutesObj = AppRoutes.getInstance();
